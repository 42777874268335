import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";

export const InstaButton = (props) => {
  return (<Button
    {...props}
    className={"insta-button " + (props.className || "")}
    color={props.color || "primary"}
    disabled={props.disabled || false}
    fullWidth={props.fullWidth || false}
    size={props.size || "large"}
    variant={props.variant || "contained"}
  >
    {props.title || props.label}
  </Button>);
};

export const SubmitButton = (props) => {
  return (<Button
    {...props}
    className={"custom-button submit-button " + (props.className || "")}
    color="primary"
    type={"submit"}
    disabled={props.disabled || false}
    fullWidth={props.fullWidth || false}
    size={props.size || "large"}
    variant={props.variant || "contained"}
  >
    {props.label || props.title || "Submit"}
  </Button>);
};

export const LinkButton = (props) => {
  const navigate = useNavigate();
  function openLink(){
    if (props.href) navigate(props.href)
  }
  return (<Button
    {...props}
    href={null}
    style={props.style}
    color={props.color || "primary"}
    type={props.type || "submit"}
    onClick={props.onClick || openLink}
    disabled={props.disabled || false}
    fullWidth={props.fullWidth || false}
    size={props.size || "large"}
    variant={props.variant || "text"}
  >
    {props.label || props.title ? props.label || props.title : props.children}
  </Button>);
};

export const CustomIconButton = (props) => {
  return (
    <IconButton
      aria-label="Record Button"
      {...props}
      className={"custom-icon-button " + (props.className || "")}
      color={props.color || "primary"}
      disabled={props.disabled || false}
      size={props.size || "large"}
      variant={props.variant || "contained"}
      component="label">
      {props.icon}
    </IconButton>
  )
}