import { combineReducers, legacy_createStore as createStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducers from "./userReducer";
import apiCallReducers from "./apiCallReducer";

const persistConfig = {
    key: 'root',
    storage,
};

const reducers = combineReducers({
    user: userReducers,
    apiCall: apiCallReducers,
});

const persistedReducer = persistReducer(persistConfig, reducers)

let store = createStore(
    persistedReducer,
);
let persistor = persistStore(store)

export {
    store, persistor
};
