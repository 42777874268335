export const appRouteNames = {
    loginPage: "/login",
    autoLogin: "/auto/login",
    dashboard: "/app/dashboard",
    advanceBookings: "/app/advance-bookings",
    manageRoom: "/app/manage/room",
    roomReportPage: "/app/report/room",
    expenseReportPage: "/app/report/expense-photos",
    roomReportPageWithAccessToken: "/app/report",
    expoenseReportPageWithAccessToken: "/app/report/expense",
};

export const USER_TYPE = {
    USER: "USER",
    ADMIN: "ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
    SUPPORT_STAFF: "SUPPORT_STAFF",
};

export const ROOM_STATUS = {
    AVAILABLE: "AVAILABLE",
    NOT_AVAILABLE: "NOT_AVAILABLE",
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// APIs
export const LOGIN_API = `${BASE_URL}/admin/login`;
export const ADD_BUILDING_API = `${BASE_URL}/admin/add/building`;
export const BUILDING_LIST_API = `${BASE_URL}/admin/buildings`;
export const MANAGE_ROOM_DATA_API = `${BASE_URL}/admin/manage-room-data`;
export const ADD_FLOOR_API = `${BASE_URL}/admin/add/floor`;
export const ADD_ROOM_API = `${BASE_URL}/admin/add/room`;
export const UPDATE_ROOM_STATUS_API = `${BASE_URL}/admin/change-room/status`;
export const ADVANCE_BOOKINGS_LIST_API = `${BASE_URL}/admin/bookings`;
export const ADVANCE_BOOKINGS_CHANGE_API = `${BASE_URL}/admin/booking/add`;
export const BOOKING_REPORT_ROOM_LIST_API = `${BASE_URL}/admin/bookings/report`;
export const ADD_BILL_TYPE_API = `${BASE_URL}/admin/add/billtypes`;
export const CONSTANTS_API = `${BASE_URL}/admin/constants`;
export const ADD_EXPENSE_PHOTOS_API = `${BASE_URL}/admin/add/billphoto`;
export const EXPENSE_PHOTOS_LIST_API = `${BASE_URL}/admin/billphotos`;