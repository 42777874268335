import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Dialog } from "@mui/material";

const InstaLoader = (props) => {
  return (
    <Dialog
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
          overflow: "hidden",
        },
      }}
      open={props.loading}
    >
      <CircularProgress size={88} className="primary-color" />
    </Dialog>
  );
};
export default InstaLoader;

InstaLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};
