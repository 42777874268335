import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

// material ui
import { Box, Grid } from "@mui/material";

// contianers
import TopNav from "./TopNav";
import Modal from "../elements/modal";
import { showError } from "../redux/apiCallReducer";
import { useDispatch, useSelector } from "react-redux";

const AuthLayout = (props) => {
  const dispatch = useDispatch();
  const showErrorModal = useSelector((state) => state?.apiCall);
  const isLoggedIn = useSelector((state) => state.user && state.user.isLoggedIn ? state.user.isLoggedIn : false);
  console.log("Auth layout: ", showErrorModal);
  return (
    <Fragment>
      {
        isLoggedIn && (
          <TopNav />
        )
      }
      <Grid className="auth-layout-grid" container spacing={1} marginY={0} alignItems="stretch">
        <Grid className="auth-nav-page-grid" item sm={12} xs={12} marginY={0}
          marginBottom={{ sm: 0, xs: 8 }}>
          <Box className="auth-nav-pages-container">
            <Outlet />
          </Box>
        </Grid>
      </Grid>
      <Modal
        useOneButton
        openModal={!!showErrorModal.errorMessage}
        cancelText="Okay"
        cancelEvent={async () => {
          await dispatch(showError({ isError: false, errorMessage: "" }));
        }}
        title={"વીરધામ"}
        modalBodyContent={showErrorModal.errorMessage}
        className='gujarati-font-model'
        type="error"
      />
    </Fragment>
  );
};

export default AuthLayout;
