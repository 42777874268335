import { useNavigate, useLocation } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { appRouteNames } from "../constants/constants";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

function LinkTab(props) {
    return (
        <Tab
            component="a"
            wrapped
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const DashboardTabs = (props) => {

    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const goTo = (pageName) => {
        navigate(pageName);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            goTo(appRouteNames.dashboard);
        } else if (newValue === 1) {
            goTo(appRouteNames.advanceBookings);
        }
    };

    useEffect(() => {
        if (location.pathname === appRouteNames.dashboard) {
            setValue(0);
        } else if (location.pathname === appRouteNames.advanceBookings) {
            setValue(1);
        }
    }, [])

    return (
        <Box className="dashboard-tabs-container">
            <Tabs className="dashboard-tabs" value={value} onChange={handleChange} aria-label="nav tabs example">
                <LinkTab label="આજની સ્થિતિ" href={appRouteNames.dashboard} />
                <LinkTab label="એડવાન્સ બુકિંગ" href={appRouteNames.advanceBookings} />
            </Tabs>
        </Box>
    );
};

export default DashboardTabs;
