import { Box, Grid } from "@mui/material";
import { InstaLabel } from "../elements/label";
import { CustomIconButton, InstaButton } from "../elements/button";
import useAPICall from "../hooks/useAPICall";
import { useEffect, useState } from "react";
import InstaLoader from "../elements/loader";
import * as _ from "lodash";
import DashboardTabs from "../containers/DashbaordTabs";
import RoomReportTable from "../containers/RoomReportTable";
import { BOOKING_REPORT_ROOM_LIST_API, EXPENSE_PHOTOS_LIST_API } from "../constants/constants";
import CustomDateInput from "../elements/form/dateInput";
import moment from "moment";
import useQuery from "../hooks/useQuery";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportTabs from "../containers/ReportsTabs";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const ExpensePhotosPage = (props) => {

    const query = useQuery();

    const [accessToken, setAccessToken] = useState('');
    const [userType, setUserType] = useState('');

    const [isProcessing, setIsProcessing] = useState(false);
    const [apiObj, setApiObj] = useState(null);
    useAPICall(apiObj);

    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(0);
    const [isLoadMoreData, setIsLoadMoreData] = useState(false);

    const getExpensePhotos = (limitItem, skipItem, token) => {
        setIsProcessing(true);
        const dataToSend = {
            limit: limitItem || limit,
            skip: skipItem || skip
        }

        let dataObj = {
            reqType: "GET",
            reqURL: EXPENSE_PHOTOS_LIST_API,
            headers: {},
            dataObj: {},
            params: dataToSend,
            successCallback: async (response) => {
                setIsProcessing(false);
                const newData = response.data.list;
                let previousData = data;
                const appendedData = [...previousData, ...newData];

                setData(appendedData);
                setCount(response.data.count);

                if (appendedData.length < response.data.count) {
                    setIsLoadMoreData(true);
                } else {
                    setIsLoadMoreData(false);
                }
            },
            failureCallback: (error) => {
                setIsProcessing(false);
                setData([]);
            },
        };

        if (accessToken) {
            dataObj['accessToken'] = accessToken;
        } else if (token) {
            dataObj['accessToken'] = `Bearer ${token}`;
        }

        setApiObj(dataObj);
    };

    const openImgInTab = (imgURL) => {
        window.open(imgURL, "_blank");
    }

    const loadMoreData = () => {
        const newSkip = skip + limit;
        setSkip(newSkip);
        getExpensePhotos(limit, newSkip);
    }

    useEffect(() => {
        const token = query.get("accessToken");
        if (token) {
            setAccessToken(`Bearer ${token}`);
        }
        getExpensePhotos(limit, skip, token);
    }, []);

    return (
        <Box>
            <ReportTabs />
            <Grid container>

                {
                    data.length !== 0 ? data.map((expense, index) => {
                        return (
                            <Grid key={index} className="expense-list-item" item xs={12} padding={2}>
                                <Box className="expense-list-item-box">
                                    <InstaLabel title={`${expense.billType} - ${moment(expense.createdAt).format("DD/MM/YYYY h:mm a")}`} className={"secondary-font w-100"} />

                                    <ImageList className="expense-img-list" cols={window.innerWidth < 400 ? 2 : 6}>
                                        {expense.photos.map((img, photoIndex) => (
                                            <ImageListItem className="img-item" key={`${index}-${photoIndex}`}>
                                                <img
                                                    src={`${img}?w=164&h=164&fit=crop&auto=format`}
                                                    srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={"Expense Photo"}
                                                    loading="lazy"
                                                    onClick={() => openImgInTab(img)}
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Box>


                            </Grid>
                        )
                    }) : (
                        <Grid item xs={12}>
                            <InstaLabel title="No Data Found" className={"text-center"} />
                        </Grid>
                    )
                }

                {
                    isLoadMoreData && (
                        <Box sx={{ textAlign: 'center', width: '100%', padding: 2 }}>
                            <InstaButton title="Load More" onClick={loadMoreData} />
                        </Box>
                    )
                }

            </Grid>
            <InstaLoader loading={isProcessing} />
        </Box>
    );
};

export default ExpensePhotosPage;
