import React, { useState } from "react";

// material UI
import Grid from "@mui/material/Grid";
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// custom elements
import { TextInput, PasswordInput } from "../elements/inputs";
import { ICSelect } from "../elements/form/selectInput";
import ICCheckbox from "../elements/form/checkbox";

// form validations
import { useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton, InstaButton } from "../elements/button";
import { Box } from "@mui/material";

const billTypeValidationSchema = yup.object({
    billType: yup
        .string()
        .required("Bill type is required"),
});

const AddBillTypesForm = (props) => {

    const formik = useFormik({
        initialValues: {
            billType: "",
        },
        validationSchema: billTypeValidationSchema,
        onSubmit: (values) => {
            props.handleSubmit(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        id="billType"
                        name="billType"
                        label="Bill Type"
                        placeholder="Bill Type"
                        value={formik.values.billType}
                        onChange={formik.handleChange}
                        error={formik.touched.billType && Boolean(formik.errors.billType)}
                        helperText={formik.touched.billType && formik.errors.billType}
                    />
                </Grid>
                <Grid item xs={12} marginY="20px" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <SubmitButton label="Submit" />
                    <InstaButton label="Close" className="ml-10" onClick={props.closeModal} variant="outlined" />
                </Grid>
            </Grid>
        </form>
    );
};

export default AddBillTypesForm;
