import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export const InstaLabel = (props) => {
  return (
    <Typography
      {...props}
      className={"insta-label " + props.className}
      margin={props.margin||"10px"}>
      {props.title}{" "}
      <sup className={props.required === true ? "asterisk" : "d-none"}>*</sup>
    </Typography>
  );
};

InstaLabel.defaultProps = {
  title: "",
  className: "",
  required: false,
};

InstaLabel.propTypes = {
  // title: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
