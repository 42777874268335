import React from "react";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const ICCheckbox = (props) => (
  <FormControlLabel
    control={
      <Checkbox
        style={props.style}
        className="ic-checkbox"
        {...props}
        checked={typeof props.value === "boolean" && props.value}
      />
    }
    label={props.label || props.title}
  />
);

export default ICCheckbox;
