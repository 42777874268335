import React from "react";

// material UI
import Grid from "@mui/material/Grid";
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// custom elements
import { TextInput, PasswordInput } from "../elements/inputs";
import { ICSelect } from "../elements/form/selectInput";

// form validations
import { useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton, InstaButton } from "../elements/button";
import { Box } from "@mui/material";

const floorFormValidationSchema = yup.object({
    floorName: yup
        .string()
        .required("floor is required"),
    buildingId: yup.string().required("Please select building"),
    sequence: yup.string().required("Please enter floor number"),
});

const FloorForm = (props) => {

    const formik = useFormik({
        initialValues: {
            floorName: "",
            buildingId: "",
            sequence: 0
        },
        validationSchema: floorFormValidationSchema,
        onSubmit: (values) => {
            props.handleSubmit(values);
        },
    });



    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ICSelect
                        style={{ marginTop: 16 }}
                        items={props.buildings}
                        value={formik.values.buildingId}
                        id="buildingId"
                        useValue="buildingName"
                        useId="_id"
                        name="buildingId"
                        label="Select Building"
                        required={true}
                        error={!!(formik.errors && formik.touched.buildingId &&
                            formik.errors.buildingId && formik.errors.buildingId)
                        }
                        handleChange={(answer) => {
                            formik.setFieldValue("buildingId", answer);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        id="floorName"
                        name="floorName"
                        label="Floor Name"
                        placeholder="Floor Name"
                        value={formik.values.floorName}
                        onChange={formik.handleChange}
                        error={formik.touched.floorName && Boolean(formik.errors.floorName)}
                        helperText={formik.touched.floorName && formik.errors.floorName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        id="sequence"
                        name="sequence"
                        label="Floor At"
                        placeholder="Floor At"
                        value={formik.values.sequence}
                        onChange={formik.handleChange}
                        error={formik.touched.sequence && Boolean(formik.errors.sequence)}
                        helperText={formik.touched.sequence && formik.errors.sequence}
                    />
                </Grid>
                <Grid item xs={12} marginY="20px" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <SubmitButton label="Submit" />
                    <InstaButton label="Close" className="ml-10" onClick={props.closeModal} variant="outlined" />
                </Grid>
            </Grid>
        </form>
    );
};

export default FloorForm;
