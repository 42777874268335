import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { appRouteNames } from "../constants/constants";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { USER_TYPE } from "../constants/constants";
import useQuery from "../hooks/useQuery";

function LinkTab(props) {
    return (
        <Tab
            component="a"
            wrapped
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const ReportTabs = (props) => {

    const query = useQuery();

    const storedUserType = useSelector((state) => state && state.user && state.user.profileData && state.user.profileData.userType ? state.user.profileData.userType : '');
    const [accessToken, setAccessToken] = useState('');
    const [userType, setUserType] = useState('');

    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const [isAccessToExpenseReport, setIsAccessToExpenseReport] = useState(false);

    const goTo = (pageName) => {
        navigate(pageName);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            let pageToUse = accessToken ? appRouteNames.roomReportPageWithAccessToken : appRouteNames.roomReportPage;
            goTo(`${pageToUse}?accessToken=${accessToken}&userType=${userType}`);
        } else if (newValue === 1) {
            let pageToUse = accessToken ? appRouteNames.expoenseReportPageWithAccessToken : appRouteNames.expenseReportPage;
            goTo(`${pageToUse}?accessToken=${accessToken}&userType=${userType}`);
        }
    };

    useEffect(() => {
        if (location.pathname === appRouteNames.roomReportPage || location.pathname === appRouteNames.roomReportPageWithAccessToken) {
            setValue(0);
        } else if (location.pathname === appRouteNames.expenseReportPage || location.pathname === appRouteNames.expoenseReportPageWithAccessToken) {
            setValue(1);
        }

        if (storedUserType === USER_TYPE.SUPER_ADMIN || props.userType === USER_TYPE.SUPER_ADMIN) {
            setIsAccessToExpenseReport(true);
        }

        const userType = query.get("userType");
        console.log("UserType is here: ", userType)
        if (userType && userType === "SUPER_ADMIN") {
            // setAccessToken(`Bearer ${token}`);
            setIsAccessToExpenseReport(true);
            setUserType(userType);
        }

        const token = query.get("accessToken");
        if (token) {
            setAccessToken(token);
        }

    }, [])

    return (
        <Box className="dashboard-tabs-container">
            <Tabs className="dashboard-tabs" value={value} onChange={handleChange} aria-label="nav tabs example">
                <LinkTab label="રૂમ રિપોર્ટ" href={`${accessToken ? appRouteNames.roomReportPageWithAccessToken : appRouteNames.roomReportPage}?accessToken=${accessToken}&userType=${userType}`} />
                {
                    isAccessToExpenseReport && (
                        <LinkTab label="ખર્ચના ફોટા" href={`${accessToken ? appRouteNames.expoenseReportPageWithAccessToken : appRouteNames.expenseReportPage}?accessToken=${accessToken}&userType=${userType}`} />
                    )
                }
            </Tabs>
        </Box>
    );
};

export default ReportTabs;
