import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Error from "../assets/images/cancel.gif";
import Success from "../assets/images/tick.gif";
import { InstaLabel } from "./label";

const Modal = (props) => (
  <Dialog
    open={props.openModal || false}
    onClose={props.cancelEvent}
    fullWidth keepMounted
    sx={{ width: "auto" }}
    maxWidth={props.maxWidth || "sm"}
    className={props.className}
    PaperProps={{
      style: {
        padding: "0px",
        borderRadius: "4px !important",
        overflow: "auto"
      }
    }}
    aria-describedby="alert-dialog-slide-description">
    <DialogTitle color={"white"} style={{
      textAlign: "center",
      padding: "12px",
      backgroundColor: "#2b4763"
    }}>{props.title}</DialogTitle>
    {/*Todo add error and warning icons*/}
    <DialogContent style={{ overflow: "auto", padding: "12px" }}>
      <Grid xs={12} container style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
        {props.type ? <>
          <img src={props.type === "error" ? Error : Success}
            style={{ width: "46px", height: "46px", paddingRight: "8px", paddingTop: "8px" }}
            alt={props.type} />
          <br />
          <div className="custom-model-body">
          {props.modalBodyContent}
          </div>
        </> : <div className="custom-model-body">{typeof props.modalBodyContent === "string" ? <InstaLabel className="text-center"
          title={props.modalBodyContent} /> : props.modalBodyContent}</div>}
      </Grid>
    </DialogContent>
    {
      !props.noFooterButtons && (
        <div className="model-footer-btn" style={{ justifyContent: "center", textAlign: "center" }}>
          <Button
            sx={{ mx: { xs: 1, xsm: 0.5 }, my: 1, display: { xs: "inline-block", sm: "inline-flex" }, minWidth: 100 }}
            className="insta-button-primary"
            variant={props.useOneButton ? "contained" : null}
            onClick={props.useOneButton ? props.cancelEvent || props.okayEvent
              : props.cancelEvent}>
            {props.useOneButton
              ? props.cancelText || props.okayText
              : props.cancelText}
          </Button>

          {!props.useOneButton && (
            <>
              &nbsp;&nbsp;
              <Button
                sx={{ mx: { xs: 1, xsm: 0.5 }, my: 1, display: { xs: "inline-block", sm: "inline-flex" }, minWidth: 100 }}
                className="insta-button-primary" variant="contained" onClick={props.okayEvent}>
                {props.okayText}
              </Button>
            </>
          )}
        </div>
      )
    }
  </Dialog>
);

export default Modal;

Modal.defaultProps = {
  modalBodyContent: "",
  openModal: false,
  className: "",
  title: "Veerdham",
  useOneButton: false,
  noFooterButtons: false
};

Modal.propTypes = {
  okayText: PropTypes.string,
  type: PropTypes.oneOf(["error", "success"]),
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.any,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  className: PropTypes.string,
  useOneButton: PropTypes.bool,
  noFooterButtons: PropTypes.bool
};
