import { Box, Grid, Card, CardContent } from "@mui/material";
import { InstaLabel } from "../elements/label";
import { InstaButton } from "../elements/button";
import useAPICall from "../hooks/useAPICall";
import { useEffect, useState } from "react";
import InstaLoader from "../elements/loader";
import * as _ from "lodash";
import Modal from "../elements/modal";
import { MANAGE_ROOM_DATA_API, ROOM_STATUS } from "../constants/constants";
import { UPDATE_ROOM_STATUS_API } from "../constants/constants";

import DashboardTabs from "../containers/DashbaordTabs";

const DashboardPage = (props) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [apiObj, setApiObj] = useState(null);
    useAPICall(apiObj);

    // data
    const [data, setData] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);

    const [currentRoomData, setCurrentRoomData] = useState(null);
    const [isConfirmRoomStatus, setIsConfirmRoomStatus] = useState(false);

    const arrangeData = (data) => {
        const floorSequenceGroup = _.groupBy(data.floors, 'sequence');

        let buildings = {};
        data.buildings.map((value, index) => {
            buildings[value._id] = value;
        });


        for (const floor in floorSequenceGroup) {
            const floorLevel = floorSequenceGroup[floor];
            for (let i = 0; i < floorLevel.length; i++) {
                if (buildings[floorLevel[i].buildingId]['floors']) {
                    // find all the rooms using floorId and add that into particular floor of building
                    let rooms = _.filter(data.rooms, function (o) { return o.floorId === floorLevel[i]._id; });
                    if (rooms.length) {
                        // sort rooms by sequence
                        const sortedRoomsBySequence = _.sortBy(rooms, [function (o) { return o.sequence; }]);
                        rooms = sortedRoomsBySequence;
                    }

                    const dataToPush = {
                        floor: floorLevel[i],
                        rooms: rooms
                    }

                    buildings[floorLevel[i].buildingId]['floors'].push(dataToPush);
                } else {
                    // find all the rooms using floorId and add that into particular floor of building
                    let rooms = _.filter(data.rooms, function (o) { return o.floorId === floorLevel[i]._id; });
                    if (rooms.length) {
                        // sort rooms by sequence
                        const sortedRoomsBySequence = _.sortBy(rooms, [function (o) { return o.sequence; }]);
                        rooms = sortedRoomsBySequence;
                    }

                    const dataToPush = {
                        floor: floorLevel[i],
                        rooms: rooms
                    }

                    buildings[floorLevel[i].buildingId]['floors'] = [dataToPush];
                }

            }
        }

        console.log("Building Updated: ", buildings);

        // convert to array now
        let buildingsArray = [];
        for (const key in buildings) {
            buildingsArray.push(buildings[key]);
        }

        setData(buildingsArray);

        console.log("Buildings Final Array: ", buildingsArray);
    }

    const getManageRoomPageData = () => {
        setIsProcessing(true);
        setApiObj({
            reqType: "GET",
            reqURL: MANAGE_ROOM_DATA_API,
            headers: {},
            dataObj: {},
            params: {},
            successCallback: async (response) => {
                setIsProcessing(false);
                setBuildings(response.data.buildings);
                setFloors(response.data.floors);
                setRooms(response.data.rooms);
                arrangeData(response.data);
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    };

    const confirmRoomStatus = (roomData, floorData, buildingData) => {
        console.log("Room status data check: ", roomData, buildingData);
        let data = roomData;
        data['buildingName'] = buildingData.buildingName;
        setCurrentRoomData(data);
        setIsConfirmRoomStatus(true);
    }

    const closeConfirmRoomStatusPopUp = () => {
        setIsConfirmRoomStatus(false);
        setCurrentRoomData(null);
    };

    const confirmChangeRoomStatus = () => {
        closeConfirmRoomStatusPopUp();

        console.log("Update this room status: ", currentRoomData);
        const dataToUpdate = {
            status: currentRoomData.status === ROOM_STATUS.AVAILABLE ? ROOM_STATUS.NOT_AVAILABLE : ROOM_STATUS.AVAILABLE,
            roomId: currentRoomData._id
        };

        setIsProcessing(true);
        setApiObj({
            reqType: "POST",
            reqURL: UPDATE_ROOM_STATUS_API,
            headers: {},
            dataObj: dataToUpdate,
            params: {},
            successCallback: async (response) => {
                getManageRoomPageData();
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    };

    useEffect(() => {
        getManageRoomPageData();
    }, []);

    return (
        <Box>
            <DashboardTabs />

            {
                data.map((building, index) => {
                    return (
                        <Card key={`building-${index}`} sx={{ width: '100%' }}>
                            <CardContent sx={{ paddingBottom: "0px" }}>
                                <InstaLabel title={building.buildingName} className="big" />

                                <Grid container spacing={2} padding={2}>
                                    {
                                        building.floors.map((floor, index) => {
                                            return (

                                                <Grid className="floor-seperator" key={`${floor}-${index}`} container sx={{ marginTop: 1 }}>
                                                    {
                                                        floor.rooms.map((room, roomIndex) => {
                                                            return (<Grid key={`${index}-${roomIndex}`} item xs={6} sm={3} md={2} padding={2}>
                                                                <InstaButton className={`w-100 room-btn-height ${room.status === ROOM_STATUS.AVAILABLE ? 'green' : 'red'}`} title={`${room.roomName}${room.hasAC ? ' - AC' : ''}`} onClick={() => confirmRoomStatus(room, floor, building)} />
                                                            </Grid>)
                                                        })
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })
            }

            <InstaLoader loading={isProcessing} />
            {/* <Modal openModal={isConfirmRoomStatus} cancelText="Close" cancelEvent={closeConfirmRoomStatusPopUp} title="Change Room Status" modalBodyContent={`Are you sure you want to update room ${currentRoomData ? currentRoomData.roomName : ''} of ${currentRoomData && currentRoomData.buildingName ? currentRoomData.buildingName : '-'} as ${currentRoomData && currentRoomData.status === ROOM_STATUS.AVAILABLE ? 'Busy' : 'Available'}?`} okayText="Confirm" okayEvent={confirmChangeRoomStatus} /> */}
            <Modal className='gujarati-font-model' openModal={isConfirmRoomStatus} cancelText="ના" cancelEvent={closeConfirmRoomStatusPopUp} title="વીરધામ" modalBodyContent={`
                શું તમે ખરેખર ${currentRoomData && currentRoomData.buildingName ? currentRoomData.buildingName : '-'} બિલ્ડીંગના રૂમ ${currentRoomData ? currentRoomData.roomName : ''} ની ${currentRoomData && currentRoomData.status === ROOM_STATUS.AVAILABLE ? 'ચાવી આપવા માંગો છો?' : 'ચાવી લીધી છે?'} `}
                okayText="હા" okayEvent={confirmChangeRoomStatus} />
        </Box>
    );
};

export default DashboardPage;
