import { useEffect, useState } from "react";
import useAPICall from "../hooks/useAPICall";
import { Box, Grid, Card, CardContent } from "@mui/material";
import DashboardTabs from "../containers/DashbaordTabs";
import InstaLoader from "../elements/loader";
import * as _ from "lodash";
import { ADVANCE_BOOKINGS_CHANGE_API, ADVANCE_BOOKINGS_LIST_API, ROOM_STATUS } from "../constants/constants";
import CustomDateInput from "../elements/form/dateInput";
import moment from "moment";
import { InstaLabel } from "../elements/label";
import { InstaButton } from "../elements/button";
import Modal from "../elements/modal";
import UnlockForm from "../containers/UnlockForm";
import RoomBookingForm from "../containers/RoomBookingForm";

const AdvanceBookingPage = (props) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [apiObj, setApiObj] = useState(null);
    useAPICall(apiObj);

    const [selectedDate, setSelectedDate] = useState();
    const [bookingData, setBookingData] = useState([]);

    const [isConfirmBookingPopUpOpen, setIsConfirmBookingPopUpOpen] = useState(false);
    const [selectedRoomData, setSelectedRoomData] = useState(null);

    const [isCancelBookingPopUp, setIsCancelBookingPopUp] = useState(false);

    const getAdvanceBookings = () => {
        const params = {
            bookingDate: selectedDate
        };

        setIsProcessing(true);
        setApiObj({
            reqType: "GET",
            reqURL: ADVANCE_BOOKINGS_LIST_API,
            headers: {},
            dataObj: {},
            params: params,
            successCallback: async (response) => {
                setIsProcessing(false);
                const data = response.data;
                let arrangedData = [];
                console.log("Response data: ", data);
                for (let key in data) {
                    let dataToPush = {
                        buildingName: key,
                        floors: []
                    };
                    for (let floorKey in data[key]) {
                        dataToPush.floors.push(data[key][floorKey]);
                    }
                    arrangedData.push(dataToPush);
                }
                console.log("Arrange Booking Data: ", arrangedData);
                setBookingData(arrangedData);
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    };

    const confirmBookingRoom = (roomData) => {
        console.log("Confirm Room Data: ", roomData);
        setSelectedRoomData(roomData);
        if (roomData.status === ROOM_STATUS.AVAILABLE) {
            setIsConfirmBookingPopUpOpen(true);
        } else {
            setIsCancelBookingPopUp(true);
        }
    }

    const closeConfirmBookingRoom = () => {
        setIsConfirmBookingPopUpOpen(false);
        setSelectedRoomData(null);
    }

    const updateRoomBooking = (formData) => {
        // if we have formData then we are adding a new booking
        console.log("Now have to update room booking: ", selectedDate, selectedRoomData);
        let dataToSend = {
            buildingId: selectedRoomData.buildingId._id,
            floorId: selectedRoomData.floorId._id,
            roomId: selectedRoomData._id,
            status: selectedRoomData.status === ROOM_STATUS.AVAILABLE ? ROOM_STATUS.NOT_AVAILABLE : ROOM_STATUS.AVAILABLE,
            bookingDate: selectedDate
        }

        if (formData && !isCancelBookingPopUp) {
            dataToSend = formData
        }

        setIsProcessing(true);
        setIsConfirmBookingPopUpOpen(false);
        setIsCancelBookingPopUp(false);



        setApiObj({
            reqType: "POST",
            reqURL: ADVANCE_BOOKINGS_CHANGE_API,
            headers: {},
            dataObj: dataToSend,
            params: {},
            successCallback: async (response) => {
                console.log("Got the response from advance booking API: ", response);
                setIsProcessing(false);
                setBookingData([]);
                getAdvanceBookings();
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    }

    const closeCancelBookingPopUp = () => {
        setIsCancelBookingPopUp(false);
        setSelectedRoomData(null);
    }

    useEffect(() => {
        if (selectedDate) {
            getAdvanceBookings();
        }
    }, [selectedDate])

    return (
        <Box>
            <DashboardTabs />
            <InstaLoader loading={isProcessing} />
            <Grid container sx={{ marginTop: 3 }}>
                <Grid item xs={12} padding={2}>
                    <CustomDateInput
                        id="bookingDate"
                        label="બુકિંગ તારીખ"
                        name="bookingDate"
                        className="cal-primary-bg"
                        defaultDate={selectedDate}
                        handleChange={(val) => {
                            console.log("Selected date: ", val);
                            setSelectedDate(val);
                        }}
                        value={selectedDate}
                        error={null}
                    />
                </Grid>
            </Grid>

            {
                bookingData.map((building, index) => {
                    return (
                        <Card key={`building-${index}`} sx={{ width: '100%' }}>
                            <CardContent sx={{ paddingBottom: "0px" }}>
                                <InstaLabel title={building.buildingName} className="big" />

                                <Grid container spacing={2} padding={2}>

                                    {
                                        building.floors.map((rooms, index) => {
                                            return (
                                                <Grid className="floor-seperator" key={`${index}-${index}`} container sx={{ marginTop: 1 }}>

                                                    {
                                                        rooms.map((room, roomIndex) => {
                                                            return (<Grid key={`${index}-${roomIndex}`} item xs={6} sm={3} md={2} padding={2}>
                                                                <InstaButton className={`w-100 room-btn-height ${room.status === ROOM_STATUS.AVAILABLE ? 'green' : 'red'}`} title={`${room.roomName}${room.hasAC ? ' - AC' : ''}`} onClick={() => confirmBookingRoom(room)} />
                                                            </Grid>)
                                                        })
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                })
            }

            <Modal className='gujarati-font-model'
                openModal={isConfirmBookingPopUpOpen}
                noFooterButtons={true}
                // cancelText="ના"
                // cancelEvent={closeConfirmBookingRoom}
                title="વીરધામ"
                // modalBodyContent={`શું તમે રૂમ ${selectedRoomData ? selectedRoomData.roomName : ''} ${selectedRoomData && selectedRoomData.status === ROOM_STATUS.AVAILABLE ? 'બુક કરવા માંગો છો?' : 'બુકિંગ રદ કરવા માંગો છો?'}`}
                modalBodyContent={<RoomBookingForm selectedDate={selectedDate} selectedRoomData={selectedRoomData} closeModal={closeConfirmBookingRoom} updateRoomBooking={updateRoomBooking} />}
            // okayText="હા"
            // okayEvent={updateRoomBooking} 
            />

            <Modal className='gujarati-font-model'
                openModal={isCancelBookingPopUp}
                cancelText="ના"
                cancelEvent={closeCancelBookingPopUp}
                title="વીરધામ"
                error={true}
                noFooterButtons={true}
                // modalBodyContent={`શું તમે રૂમ ${selectedRoomData ? selectedRoomData.roomName : ''} ${selectedRoomData && selectedRoomData.status === ROOM_STATUS.AVAILABLE ? 'બુક કરવા માંગો છો?' : 'બુકિંગ રદ કરવા માંગો છો?'}`}
                modalBodyContent={<UnlockForm selectedRoomData={selectedRoomData} closeModal={closeCancelBookingPopUp} handleSubmit={updateRoomBooking} />}
                okayText="હા"
                okayEvent={updateRoomBooking}
            />
        </Box>
    );
};

export default AdvanceBookingPage;
