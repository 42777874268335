import { Box, Grid } from "@mui/material";
import { InstaLabel } from "../elements/label";
import { CustomIconButton, InstaButton } from "../elements/button";
import useAPICall from "../hooks/useAPICall";
import { useEffect, useState } from "react";
import InstaLoader from "../elements/loader";
import * as _ from "lodash";
import DashboardTabs from "../containers/DashbaordTabs";
import RoomReportTable from "../containers/RoomReportTable";
import { BOOKING_REPORT_ROOM_LIST_API } from "../constants/constants";
import CustomDateInput from "../elements/form/dateInput";
import moment from "moment";
import useQuery from "../hooks/useQuery";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportTabs from "../containers/ReportsTabs";

const RoomReportPage = (props) => {

    const query = useQuery();

    const [accessToken, setAccessToken] = useState('');

    const [isProcessing, setIsProcessing] = useState(false);
    const [apiObj, setApiObj] = useState(null);
    useAPICall(apiObj);

    const [startDate, setStartDate] = useState(null);
    const [data, setData] = useState([]);
    const [columnHeaders, setColumnHeaders] = useState([]);

    const getRoomData = () => {
        setIsProcessing(true);
        const dataToSend = {
            startDate: moment(startDate, "DD/MM/YYYY").startOf("d").toISOString(),
            endDate: moment(startDate, "DD/MM/YYYY").add(1, 'month').toISOString()
        }

        let dataObj = {
            reqType: "GET",
            reqURL: BOOKING_REPORT_ROOM_LIST_API,
            headers: {},
            dataObj: {},
            params: dataToSend,
            successCallback: async (response) => {
                setIsProcessing(false);
                const parsedData = JSON.parse(response.data);
                let headers = [];
                if (parsedData.length) {
                    for (let key in parsedData[0]) {
                        headers.push({
                            id: key,
                            label: key.toUpperCase()
                        });
                    }
                }
                setColumnHeaders(headers);
                setData(parsedData);
            },
            failureCallback: (error) => {
                setIsProcessing(false);
                setData([]);
            },
        };

        if (accessToken) {
            dataObj['accessToken'] = accessToken;
        }

        setApiObj(dataObj);
    }

    const reloadData = () => {
        getRoomData();
    }

    useEffect(() => {
        if (startDate) {
            getRoomData();
        }
    }, [startDate]);

    useEffect(() => {
        const token = query.get("accessToken");
        if (token) {
            setAccessToken(`Bearer ${token}`);
        }
    }, []);

    return (
        <Box>
            <ReportTabs />
            <Grid container>
                <Grid item xs={8} padding={2}>
                    <InstaLabel title="Room Report" className={"big"} />
                </Grid>
                <Grid item xs={4} padding={2} sx={{ textAlign: 'end', paddingTop: 3 }}>
                    {
                        startDate && (
                            <CustomIconButton className="big" variant="contained" size="large" icon={<RefreshIcon />} onClick={reloadData} />
                        )
                    }
                </Grid>
                <Grid item xs={12} padding={2}>
                    <CustomDateInput
                        id="startDate"
                        label="બુકિંગ તારીખ"
                        name="startDate"
                        className="cal-primary-bg"
                        defaultDate={startDate}
                        handleChange={(val) => {
                            setStartDate(val);
                        }}
                        value={startDate}
                        error={null}
                    />

                </Grid>
                <Grid item xs={12} padding={2}>
                    <RoomReportTable data={data} headers={columnHeaders} />
                </Grid>
            </Grid>
            <InstaLoader loading={isProcessing} />
        </Box>
    );
};

export default RoomReportPage;
