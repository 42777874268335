import React from "react";

// material ui
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
// import HelpIcon from '@mui/icons-material/Help';
// import DescriptionIcon from "@mui/icons-material/Description";
import DashboardIcon from '@mui/icons-material/Dashboard';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

// elements
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { USER_TYPE, appRouteNames } from "../constants/constants";
import { setIsLoggedIn, setProfileData } from "../redux/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../redux/apiCallReducer";

const UserTopNavAvatar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.user?.profileData);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function goTo(pageName) {
    navigate(pageName);
  }

  function logout() {
    dispatch(showError({ isError: false, errorMessage: "" }));
    dispatch(setProfileData(null));
    dispatch(setIsLoggedIn(false));
  }

  function getIcon() {
    if (
      profileData &&
      profileData.profilePic &&
      profileData.profilePic.original
    ) {
      return (
        <Avatar
          sx={{ width: 50, height: 50 }}
          src={profileData.profilePic.original}
        />
      );
    }
    return (
      <Avatar sx={{ width: 50, height: 50 }}></Avatar>
    );
  }

  return (
    <Box className="user-top-nav-avatar-dd">
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          // size="small"
          // sx={{ ml: 2 }}
          className="user-icon-dd"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}>
          {getIcon()}
          <Typography sx={{ marginLeft: 1, display: 'flex', justifyContent: 'center' }}>{profileData.firstName} {profileData.lastName} <ArrowDropDownCircleIcon sx={{ marginLeft: 1 }} /></Typography>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        className="menu"
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 25,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {/* <MenuItem
          className="menu-item" onClick={() => {
            goTo(appRouteNames.profile);
          }}>
          {getIcon()}
          <Typography sx={{ marginLeft: "10px" }}>Profile</Typography>
        </MenuItem> */}
        {/* <MenuItem
          className="menu-item"
          onClick={() => goTo(appRouteNames.changePassword)}
        >
          <ListItemIcon>
            <LockIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ marginLeft: "10px" }}>Change Password</Typography>
        </MenuItem> */}
        {/* <MenuItem
          className="menu-item"
          onClick={() => goTo(appRouteNames.help)}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <ListItemIcon>
            <HelpIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ marginLeft: "10px" }}>Help</Typography>
        </MenuItem>
        <MenuItem
          className="menu-item"
          onClick={() => goTo(appRouteNames.legal)}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <ListItemIcon>
            <DescriptionIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ marginLeft: "10px" }}>Legal</Typography>
        </MenuItem> */}

        <MenuItem
          className="menu-item"
          onClick={() => goTo(appRouteNames.dashboard)}
        >
          <ListItemIcon>
            <DashboardIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ marginLeft: "10px" }}>Dashboard</Typography>
        </MenuItem>

        {
          profileData.userType === USER_TYPE.SUPER_ADMIN && (
            <MenuItem
              className="menu-item"
              onClick={() => goTo(appRouteNames.manageRoom)}
            >
              <ListItemIcon>
                <RoomPreferencesIcon fontSize="medium" />
              </ListItemIcon>
              <Typography sx={{ marginLeft: "10px" }}>Manage Rooms</Typography>
            </MenuItem>
          )
        }


        <MenuItem
          className="menu-item"
          onClick={() => goTo(appRouteNames.roomReportPage)}
        >
          <ListItemIcon>
            <AssessmentIcon fontSize="medium" />
          </ListItemIcon>
          <Typography sx={{ marginLeft: "10px" }}>Room Report</Typography>
        </MenuItem>

        <MenuItem className="menu-item" onClick={logout}>
          {/*todo*/}
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserTopNavAvatar;
