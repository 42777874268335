import React from "react";

// material UI
import Grid from "@mui/material/Grid";
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// custom elements
import { TextInput, PasswordInput } from "../elements/inputs";

// form validations
import { useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton, InstaButton } from "../elements/button";
import { InstaLabel } from "../elements/label";
import { ROOM_STATUS } from "../constants/constants";

const unlockFormValidationSchema = yup.object({
    password: yup
        .string("Enter your password")
        .min(4, "Please enter corrent password")
        .max(4, "Please enter corrent password")
        .required("Password is required"),
});

const UnlockForm = (props) => {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema: unlockFormValidationSchema,
        onSubmit: (values) => {
            if (values.password === "2022") {
                props.handleSubmit(values);
                formik.resetForm();
            } else {
                formik.resetForm();
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InstaLabel className={"red-text"} title={`શું તમે રૂમ ${props.selectedRoomData ? props.selectedRoomData.roomName : ''} ${props.selectedRoomData && props.selectedRoomData.status === ROOM_STATUS.AVAILABLE ? 'બુક કરવા માંગો છો?' : 'બુકિંગ રદ કરવા માંગો છો?'}`} />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        id="password"
                        name="password"
                        label="Password"
                        type={'text'}
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                </Grid>
                <Grid item xs={12} textAlign="center" marginY="20px" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <InstaButton label="ના" className="ml-10 font-22" onClick={props.closeModal} variant="outlined" />
                    <SubmitButton label="હા" className="font-22" />
                </Grid>
            </Grid>
        </form>
    );
};

export default UnlockForm;
