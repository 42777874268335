import * as React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

// redux operations
import { useSelector } from "react-redux";

// constants
import { appRouteNames } from "../constants/constants";

// layout
import Layout from "../containers/Layout";
import AuthLayout from "../containers/AuthLayout";

// ******* Public Pages *********
import LoginPage from "../pages/LoginPage";

// ******* Private Pages *********
import DashboardPage from "../pages/DashboardPage";
import ManageRoomPage from "../pages/ManageRoomPage";
import AdvanceBookingPage from "../pages/AdvanceBookingPage";
import RoomReportPage from "../pages/RoomReportPage";
import ExpensePhotosPage from "../pages/ExpensePhotosPage";

const CanLoad = (props) => {
    console.log("Can Load Component? ", props.isLoggedIn, props.isPrivateComponent);
    if (props.isLoggedIn && props.isPrivateComponent) {
        // means you want to allow access to only authenticated users only
        return props.component;
    } else if (!props.isLoggedIn && props.isPrivateComponent === false) {
        // means you want to use public component and allow use to all users
        return props.component;
    } else if (props.isLoggedIn) {
        return <Navigate to={appRouteNames.dashboard} />
    } else {
        return <Navigate to="/login" />
    }
}


const AppRouter = (props) => {
    const appStates = useSelector((state) => state);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

    return (
        <Router>
            <React.Fragment>
                <Routes>
                    {/* Todo 404 */}
                    <Route exact path='/' element={<Navigate to={appRouteNames.loginPage} />} />
                    <Route path='/' element={<Layout />}>
                        <Route path={appRouteNames.loginPage} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={false} component={<LoginPage />} />} />
                        <Route path={appRouteNames.roomReportPageWithAccessToken} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={false} component={<RoomReportPage />} />} />
                        <Route path={appRouteNames.expoenseReportPageWithAccessToken} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={false} component={<ExpensePhotosPage />} />} />
                    </Route>
                    {/* Authenticated Routes */}
                    <Route path='/' element={<AuthLayout />}>
                        <Route path={appRouteNames.dashboard} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={true} component={<DashboardPage />} />} />
                        <Route path={appRouteNames.manageRoom} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={true} component={<ManageRoomPage />} />} />
                        <Route path={appRouteNames.advanceBookings} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={true} component={<AdvanceBookingPage />} />} />
                        <Route path={appRouteNames.roomReportPage} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={true} component={<RoomReportPage />} />} />
                        <Route path={appRouteNames.expenseReportPage} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={true} component={<ExpensePhotosPage />} />} />
                        {/* Example of id route param */}
                        {/* <Route path={appRouteNames.startAssessment + "/:id"} element={<CanLoad isLoggedIn={isLoggedIn} isPrivateComponent={true} component={<AssessmentPage />} />} /> */}
                    </Route>
                </Routes>
            </React.Fragment>
        </Router>
    )
}



export default AppRouter;
