// packages
import { useNavigate } from "react-router-dom";

// redux operations
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setProfileData } from "../redux/userReducer";

// material ui
import { Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

// assets
import SmallIconLogo from "../assets/images/small-logo.png";

// forms
import LoginForm from "../containers/LoginForm";

// constants
import { appRouteNames, LOGIN_API } from "../constants/constants";
import useAPICall from "../hooks/useAPICall";
import { LinkButton } from "../elements/button";
import { useState } from "react";
import Modal from "../elements/modal";
import InstaLoader from "../elements/loader";

const LoginPage = (props) => {
    const [modalState, setModalState] = useState(null);
    const profileData = useSelector((state) => state?.user?.profileData);
    const [apiObj, setApiObj] = useState(null);
    useAPICall(apiObj);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    if (profileData) goTo(appRouteNames.dashboard);

    function goTo(pageName) {
        navigate(pageName);
    }

    const handleLogin = async (formData) => {
        //? API Call Example
        setIsProcessing(true);
        setApiObj({
            reqType: "POST",
            reqURL: LOGIN_API,
            headers: {},
            dataObj: formData,
            params: {},
            successCallback: async (response) => {
                await dispatch(setProfileData(response.data));
                await dispatch(setIsLoggedIn(true));
                setIsProcessing(false);
                goTo(appRouteNames.dashboard);
            },
            failureCallback: (error) => {
                setIsProcessing(false);
                setModalState({
                    open: true,
                    useOneButton: true,
                    type: "error",
                    title: "Veerdham",
                    modalBodyContent: getErrorMessage(error),
                    okayText: "Okay",
                    okayEvent: () => setModalState(null)
                });
            },
            showErrorMsg: false
        });
    };

    function getErrorMessage(error) {
        if (error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        }
        return "Failed to log in";
    }

    return (
        <Grid className="login-page" container marginTop="40px" spacing={1}
            padding="10px" justifyContent="center" alignItems="stretch">
            <Grid item lg={4} md={6} sm={8} xsm={10} xs={12}
                justifyContent="center" alignItems="center">
                <Card>
                    <div className="small-logo-container">
                        <img src={SmallIconLogo} alt="Small Logo" className="small-logo" />
                    </div>
                    <CardContent sx={{ paddingBottom: "0px" }}>
                        <Typography marginTop="30px" marginBottom="30px" gutterBottom
                            variant="h5" component="div" textAlign="center" color="primary">
                            Login
                        </Typography>
                        <LoginForm handleSubmit={handleLogin}></LoginForm>
                    </CardContent>
                    {/* 
                    <LinkButton
                        sx={{ mx: { xs: 1, xsm: 0.5 }, display: { xs: "inline-block", md: "inline-flex" } }}
                        href={appRouteNames.forgotPassword}
                        color="secondary"
                        title="Forgot Password?"
                        className="btn-text-gray"
                    /> */}

                    <div className="border-bottom w-50 mx-auto"></div>

                    {/* <CardActions sx={{ justifyContent: "space-between" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%"
                            }}
                            justifyContent={{ xs: "start", sm: "space-between" }}
                        >
                            <LinkButton color="primary" href={"/signup"}
                                sx={{ mx: { xs: 1, xsm: 0.5 }, my: 0.4, display: { xs: "inline-block", md: "inline-flex" } }}>
                                <Typography color="secondary" className="btn-text-gray">
                                    Don't have an account?{" "} <span className="primary-text">Sign up</span>
                                </Typography>
                            </LinkButton>
                        </Box>
                    </CardActions> */}
                </Card>
            </Grid>
            <Modal openModal={!!modalState} {...modalState} />
            <InstaLoader loading={isProcessing} />
        </Grid>
    );
};

export default LoginPage;
