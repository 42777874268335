import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useRef, useState } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const RoomReportTable = (props) => {

    const audioRef = useRef();
    const [audioURL, setAudioURL] = useState('');

    const playAudio = (url) => {
        setAudioURL(url);
        audioRef.current.src = url;
        audioRef.current.play();
    }

    return (
        <Box>
        <Paper className="table-paper sticky-table" sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer className="table-header" sx={{ maxHeight: 440 }}>
                <Table className="table" stickyHeader aria-label="sticky table">
                    <TableHead className="table-head">
                        <TableRow>
                            {props.headers.map((column, index) => (
                                <TableCell
                                    key={column.id}
                                    className={index === 0 ? 'sticky-column' : ''}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className='table-body'>
                        {/* {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column, columnIndex) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell className={columnIndex === 0 ? 'sticky-column' : ''} key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })} */}

                        {
                            props.data.map((row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                        {props.headers.map((column, columnIndex) => {
                                            const value = row[column.id];
                                            // value could be empty (if room is not available then it will be 'Y')
                                            const columnBackground = columnIndex !== 0 && value.status ? 'room-not-available' : 'room-available';
                                            return (
                                                <TableCell className={columnIndex === 0 ? 'sticky-column' : (columnIndex !== 0 ? columnBackground : '')} key={column.id} align={column.align} onClick={() => playAudio(value.audio)}>
                                                    {
                                                        columnIndex === 0 && row['room']
                                                    }

                                                    {
                                                        columnIndex !== 0 && value.audio && (
                                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <PlayCircleIcon color='primary' />
                                                            </Box>
                                                        )
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
            <Box sx={{ display: 'none' }}>
                <audio ref={audioRef} id="audioRef" controls>
                    <source src={audioURL} />
                </audio>
            </Box>
        </Box>
    )
};

export default RoomReportTable;