import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

export const ICSelect = (props) => {
  const handleChange = (event) => {
    props.handleChange(event.target.value);
  };
  const generateOptions = () => {
    let menuItems = [];

    props.items.map((val, key) => {
      return menuItems.push(
        <MenuItem key={key} value={props.useId ? val[props.useId] : val}>
          {props.useValue ? val[props.useValue] : val}
        </MenuItem>
      );
    });
    return menuItems;
  };

  return (
    <FormControl
      disabled={props.disabled}
      style={props.style}
      className={"ic-select w-100 " + (props.className ? props.className : "")}
      id={props.id}
      name={props.name}>
      <InputLabel
        style={{ paddingLeft: 4, paddingRight: 4, backgroundColor: "white" }}
        id={props.id} error={!!props.error}>
        {props.label}
      </InputLabel>
      <Select
        error={!!props.error}
        labelId={props.id}
        id={props.id}
        name={props.name}
        value={props.value || ""}
        onChange={handleChange}
        onBlur={props.onBlur}>

        {props.items && props.items.length ? generateOptions() : ""}
      </Select>
      {props.helperText &&
        <FormHelperText error={!!props.error}>{props.helperText}</FormHelperText>
      }
    </FormControl>
  );
};

export const ICMultiSelect = (props) => {
  const handleChange = (event) => {
    props.handleChange(event.target.value);
  };
  const generateOptions = () => {
    let menuItems = [];
    props.items.map((val, key) => {
      return menuItems.push(
        <MenuItem key={key} value={props.useId ? val[props.useId] : val}>
          <Checkbox checked={!!(props.value && props.value.indexOf(val) > -1)} />
          {props.useValue ? val[props.useValue] : val}
        </MenuItem>
      );
    });
    return menuItems;
  };

  return (
    <FormControl
      style={props.style}
      className={"ic-select w-100 " + (props.className ? props.className : "")}
      id={props.id}
      name={props.name}
    >
      <InputLabel
        style={{ paddingLeft: 4, paddingRight: 4, backgroundColor: "white" }}
        id={props.id}
      >
        {props.label}
      </InputLabel>
      <Select
        // label={props.label}
        multiple
        renderValue={(selected) => selected.join(", ")}
        labelId={props.id}
        id={props.id}
        name={props.name}
        value={props.value || []}
        onChange={handleChange}
        onBlur={props.onBlur}
      >
        {props.placeholder ? (
          <MenuItem
            className="select-default-value"
            value={props.placeholder}
            disabled
          >
            {props.placeholder}
          </MenuItem>
        ) : null}

        {props.items && props.items.length ? generateOptions() : ""}
      </Select>
    </FormControl>
  );
};