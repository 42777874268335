import React from "react";

// material UI
import Grid from "@mui/material/Grid";
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// custom elements
import { TextInput, PasswordInput } from "../elements/inputs";

// form validations
import { useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton, InstaButton } from "../elements/button";
import { Box } from "@mui/material";

const buildingFormValidationSchema = yup.object({
    buildingName: yup
        .string("Building name is required")
        .required("Email is required"),
});

const BuildingForm = (props) => {

    const formik = useFormik({
        initialValues: {
            buildingName: "",
        },
        validationSchema: buildingFormValidationSchema,
        onSubmit: (values) => {
            props.handleSubmit(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        id="buildingName"
                        name="buildingName"
                        label="Building Name"
                        placeholder="Building Name"
                        value={formik.values.buildingName}
                        onChange={formik.handleChange}
                        error={formik.touched.buildingName && Boolean(formik.errors.buildingName)}
                        helperText={formik.touched.buildingName && formik.errors.buildingName}
                    />
                </Grid>
                <Grid item xs={12} marginY="20px" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <SubmitButton label="Submit" />
                    <InstaButton label="Close" className="ml-10" onClick={props.closeModal} variant="outlined" />
                </Grid>
            </Grid>
        </form>
    );
};

export default BuildingForm;
