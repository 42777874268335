import React, { useState } from "react";

// material UI
import Grid from "@mui/material/Grid";
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// custom elements
import { TextInput, PasswordInput } from "../elements/inputs";
import { ICSelect } from "../elements/form/selectInput";
import ICCheckbox from "../elements/form/checkbox";

// form validations
import { useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton, InstaButton } from "../elements/button";
import { Box } from "@mui/material";

const roomFormValidationSchema = yup.object({
    roomName: yup
        .string()
        .required("Room name is required"),
    buildingId: yup.string().required("Please select building"),
    floorId: yup.string().required("Please select the floor"),
    hasAC: yup.bool().default(false),
});

const FloorForm = (props) => {

    const [floors, setFloors] = useState([]);

    const formik = useFormik({
        initialValues: {
            roomName: "",
            buildingId: "",
            floorId: "",
            hasAC: false,
        },
        validationSchema: roomFormValidationSchema,
        onSubmit: (values) => {
            props.handleSubmit(values);
        },
    });

    const changeFloorOptions = (buildingId) => {
        console.log("Selected building: ", buildingId);
        console.log("Look from: ", props.floors);
        const filterFloors = props.floors.filter((floor) => floor.buildingId === buildingId);
        setFloors(filterFloors);
    }



    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ICSelect
                        style={{ marginTop: 16 }}
                        items={props.buildings}
                        value={formik.values.buildingId}
                        id="buildingId"
                        useValue="buildingName"
                        useId="_id"
                        name="buildingId"
                        label="Select Building"
                        required={true}
                        error={!!(formik.errors && formik.touched.buildingId &&
                            formik.errors.buildingId && formik.errors.buildingId)
                        }
                        handleChange={(answer) => {
                            changeFloorOptions(answer);
                            formik.setFieldValue("buildingId", answer);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ICSelect
                        items={floors}
                        value={formik.values.floorId}
                        id="floorId"
                        useValue="floorName"
                        useId="_id"
                        name="floorId"
                        label="Select Floor"
                        required={true}
                        error={!!(formik.errors && formik.touched.floorId &&
                            formik.errors.floorId && formik.errors.floorId)
                        }
                        handleChange={(floorId) => {
                            formik.setFieldValue("floorId", floorId);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        id="roomName"
                        name="roomName"
                        label="Room Name"
                        placeholder="Room Name"
                        value={formik.values.roomName}
                        onChange={formik.handleChange}
                        error={formik.touched.roomName && Boolean(formik.errors.roomName)}
                        helperText={formik.touched.roomName && formik.errors.roomName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ICCheckbox
                        id="hasAC"
                        name="hasAC"
                        onChange={(e) => {
                            formik.setFieldValue("hasAC", e.target.checked);
                        }}
                        label="Has AC?"
                        onBlur={formik.handleBlur}
                        value={formik.values.hasAC}
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </Grid>
                <Grid item xs={12} marginY="20px" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <SubmitButton label="Submit" />
                    <InstaButton label="Close" className="ml-10" onClick={props.closeModal} variant="outlined" />
                </Grid>
            </Grid>
        </form>
    );
};

export default FloorForm;
