import { Box, Grid, Card, CardContent, List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import { InstaLabel } from "../elements/label";
import { InstaButton } from "../elements/button";
import { useEffect, useState } from "react";
import Modal from "../elements/modal";
import BuildingForm from "../containers/BuildingForm";
import AddFloorForm from "../containers/AddFloorForm";
import AddRoomForm from "../containers/AddRoomForm";
import AddBillTypeForm from "../containers/AddBillTypeForm";
import useAPICall from "../hooks/useAPICall";
import InstaLoader from "../elements/loader";
import { ADD_BILL_TYPE_API, ADD_BUILDING_API, ADD_FLOOR_API, ADD_ROOM_API, MANAGE_ROOM_DATA_API, ROOM_STATUS } from "../constants/constants";
import * as _ from "lodash";

const ManageRoomPage = (props) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [apiObj, setApiObj] = useState(null);
    useAPICall(apiObj);
    const [modalState, setModalState] = useState(null);

    // data
    const [buildings, setBuildings] = useState([]);
    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [expenseTypes, setExpenseTypes] = useState([]);

    const [isOpenBuildingAddPopUp, setIsOpenBuldingAddPopUp] = useState(false);
    const [isOpenAddFloorPopUp, setIsOpenAddFloorPopUp] = useState(false);
    const [isOpenAddRoomPopUp, setIsOpenAddRoomPopUp] = useState(false);

    const [isOpenAddExpenseTypePopUp, setIsOpenAddExpenseTypePopUp] = useState(false);

    const [data, setData] = useState([]);

    const openAddBuildingPopup = () => {
        setIsOpenBuldingAddPopUp(true);
    }

    const closeAddBuldingAddPopUp = () => {
        setIsOpenBuldingAddPopUp(false);
    }

    const handleAddBuilding = (formData) => {
        console.log("Now have to add this building: ", formData);
        closeAddBuldingAddPopUp();
        setIsProcessing(true);
        setApiObj({
            reqType: "POST",
            reqURL: ADD_BUILDING_API,
            headers: {},
            dataObj: formData,
            params: {},
            successCallback: async (response) => {
                setIsProcessing(false);
                setModalState({
                    open: true,
                    useOneButton: true,
                    type: "success",
                    title: "Veerdham",
                    modalBodyContent: "Building Addedd Successfully",
                    okayText: "Okay",
                    okayEvent: () => setModalState(null)
                });
                getManageRoomPageData();
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    };

    const arrangeData = (data) => {
        const floorSequenceGroup = _.groupBy(data.floors, 'sequence');

        let buildings = {};
        data.buildings.map((value, index) => {
            buildings[value._id] = value;
        });


        for (const floor in floorSequenceGroup) {
            const floorLevel = floorSequenceGroup[floor];
            for (let i = 0; i < floorLevel.length; i++) {
                if (buildings[floorLevel[i].buildingId]['floors']) {
                    // find all the rooms using floorId and add that into particular floor of building
                    let rooms = _.filter(data.rooms, function (o) { return o.floorId === floorLevel[i]._id; });
                    if (rooms.length) {
                        // sort rooms by sequence
                        const sortedRoomsBySequence = _.sortBy(rooms, [function (o) { return o.sequence; }]);
                        rooms = sortedRoomsBySequence;
                    }

                    const dataToPush = {
                        floor: floorLevel[i],
                        rooms: rooms
                    }

                    buildings[floorLevel[i].buildingId]['floors'].push(dataToPush);
                } else {
                    // find all the rooms using floorId and add that into particular floor of building
                    let rooms = _.filter(data.rooms, function (o) { return o.floorId === floorLevel[i]._id; });
                    if (rooms.length) {
                        // sort rooms by sequence
                        const sortedRoomsBySequence = _.sortBy(rooms, [function (o) { return o.sequence; }]);
                        rooms = sortedRoomsBySequence;
                    }

                    const dataToPush = {
                        floor: floorLevel[i],
                        rooms: rooms
                    }

                    buildings[floorLevel[i].buildingId]['floors'] = [dataToPush];
                }

            }
        }

        // convert to array now
        let buildingsArray = [];
        for (const key in buildings) {
            buildingsArray.push(buildings[key]);
        }

        setData(buildingsArray);
    }

    const getManageRoomPageData = () => {
        setIsProcessing(true);
        setApiObj({
            reqType: "GET",
            reqURL: MANAGE_ROOM_DATA_API,
            headers: {},
            dataObj: {},
            params: {},
            successCallback: async (response) => {
                console.log("Response: ", response);
                setIsProcessing(false);
                setBuildings(response.data.buildings);
                setFloors(response.data.floors);
                setRooms(response.data.rooms);
                const billTypes = response.data.constant && response.data.constant.billTypes ? response.data.constant.billTypes : [];
                setExpenseTypes(billTypes);
                arrangeData(response.data);
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    };

    const openAddFloorPopUp = () => {
        setIsOpenAddFloorPopUp(true);
    }

    const closeAddFloorPopUp = () => {
        setIsOpenAddFloorPopUp(false);
    }

    const handleAddFloor = (formData) => {
        console.log("Now have to add this building: ", formData);
        closeAddFloorPopUp();
        setIsProcessing(true);

        setApiObj({
            reqType: "POST",
            reqURL: ADD_FLOOR_API,
            headers: {},
            dataObj: formData,
            params: {},
            successCallback: async (response) => {
                setIsProcessing(false);
                setModalState({
                    open: true,
                    useOneButton: true,
                    type: "success",
                    title: "Veerdham",
                    modalBodyContent: "Floor Addedd Successfully",
                    okayText: "Okay",
                    okayEvent: () => setModalState(null)
                });
                getManageRoomPageData();
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    };

    const openAddRoomPopUp = () => {
        setIsOpenAddRoomPopUp(true);
    };

    const closeAddRoomPopUp = () => {
        setIsOpenAddRoomPopUp(false);
    }

    const handleAddRoom = (formData) => {
        console.log("Now have to add this building: ", formData);
        closeAddRoomPopUp();
        setIsProcessing(true);

        const roomsOnFloor = rooms.filter((room) => room.floorId === formData.floorId);
        console.log("We got roomsOnFloor: ", roomsOnFloor);
        formData['sequence'] = roomsOnFloor.length + 1;

        setApiObj({
            reqType: "POST",
            reqURL: ADD_ROOM_API,
            headers: {},
            dataObj: formData,
            params: {},
            successCallback: async (response) => {
                setIsProcessing(false);
                setModalState({
                    open: true,
                    useOneButton: true,
                    type: "success",
                    title: "Veerdham",
                    modalBodyContent: "Room Addedd Successfully",
                    okayText: "Okay",
                    okayEvent: () => setModalState(null)
                });
                getManageRoomPageData();
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    }

    const openAddExpensePopUp = () => {
        setIsOpenAddExpenseTypePopUp(true);
    };

    const closeAddExpensePopUp = () => {
        setIsOpenAddExpenseTypePopUp(false);
    }

    const handleAddBillType = (formData) => {
        console.log("Now have to add this bill type: ", formData);
        closeAddExpensePopUp();
        setIsProcessing(true);

        setApiObj({
            reqType: "POST",
            reqURL: ADD_BILL_TYPE_API,
            headers: {},
            dataObj: formData,
            params: {},
            successCallback: async (response) => {
                setIsProcessing(false);
                setModalState({
                    open: true,
                    useOneButton: true,
                    type: "success",
                    title: "Veerdham",
                    modalBodyContent: "Bill type Addedd Successfully",
                    okayText: "Okay",
                    okayEvent: () => setModalState(null)
                });
                getManageRoomPageData();
            },
            failureCallback: (error) => {
                setIsProcessing(false);
            },
        });
    }

    useEffect(() => {
        getManageRoomPageData();
    }, []);

    return (
        <Box>
            <Grid className="manage-room-page" container padding="10px">
                <InstaLabel title="Manage Rooms" className="big" />
                <div className="w-100 border-bottom" />


                <Grid container padding="10px" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <InstaButton fullWidth={true} title='Add Building' onClick={openAddBuildingPopup} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InstaButton className={`w-100`} title='Add Floor' onClick={openAddFloorPopUp} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InstaButton className={`w-100`} title='Add Room' onClick={openAddRoomPopUp} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InstaButton className={`w-100`} title='Add Bill Type' onClick={openAddExpensePopUp} />
                    </Grid>

                </Grid>


                {
                    data.map((building, index) => {
                        return (
                            <Card key={`building-${index}`} sx={{ width: '100%' }}>
                                <CardContent sx={{ paddingBottom: "0px" }}>
                                    <InstaLabel title={building.buildingName} className={"secondary-font "} />

                                    <Grid container spacing={2} padding={2}>
                                        {
                                            building.floors && building.floors.map((floor, index) => {
                                                return (

                                                    <Grid className="floor-seperator" key={`${floor}-${index}`} container sx={{ marginTop: 1 }}>
                                                        {
                                                            floor.rooms.map((room, roomIndex) => {
                                                                return (<Grid key={`${index}-${roomIndex}`} item xs={6} sm={3} md={2} padding={2}>
                                                                    <InstaButton className={`w-100 room-btn-height ${room.status === ROOM_STATUS.AVAILABLE ? 'green' : 'red'}`} title={`${room.roomName}${room.hasAC ? ' - AC' : ''}`} />
                                                                </Grid>)
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        )
                    })
                }

            </Grid>

            {/* Bill Types */}
            <Card sx={{ width: '100%' }}>
                <CardContent sx={{ paddingBottom: "0px" }}>
                    <InstaLabel title={"Bill Types"} className={"secondary-font "} />
                    <Grid container spacing={2} padding={2}>
                        {
                            expenseTypes.map((expense, index) => {
                                return (

                                    <Grid key={`${index}`} item xs={6} sm={3} md={2} padding={2}>
                                        <InstaLabel className={"expense-type-label"} title={expense} />
                                    </Grid>

                                )
                            })
                        }
                    </Grid>
                </CardContent>
            </Card>


            <Modal openModal={isOpenBuildingAddPopUp} cancelEvent={closeAddBuldingAddPopUp} title="Add New Building" modalBodyContent={<BuildingForm closeModal={closeAddBuldingAddPopUp} handleSubmit={handleAddBuilding} />} noFooterButtons={true} />
            <Modal openModal={isOpenAddFloorPopUp} cancelEvent={closeAddFloorPopUp} title="Add New Floor" modalBodyContent={<AddFloorForm closeModal={closeAddFloorPopUp} buildings={buildings} handleSubmit={handleAddFloor} />} noFooterButtons={true} />
            <Modal openModal={isOpenAddRoomPopUp} cancelEvent={closeAddRoomPopUp} title="Add New Room" modalBodyContent={<AddRoomForm closeModal={closeAddRoomPopUp} buildings={buildings} floors={floors} handleSubmit={handleAddRoom} />} noFooterButtons={true} />
            <Modal openModal={isOpenAddExpenseTypePopUp} cancelEvent={closeAddExpensePopUp} title="Add New Bill Type" modalBodyContent={<AddBillTypeForm closeModal={closeAddExpensePopUp} handleSubmit={handleAddBillType} />} noFooterButtons={true} />
            <InstaLoader loading={isProcessing} />
            <Modal openModal={!!modalState} {...modalState} />
        </Box>
    );
};

export default ManageRoomPage;
