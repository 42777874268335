import React, { useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

function CustomDateInput(props) {
  const [selectedDate, setSelectedDate] = useState(props.defaultDate === "" ? "" : moment(props.defaultDate, props.inputFormat));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        label={props.label || "Enter Date"}
        inputFormat={props.inputFormat}
        format="DD/MM/YYYY"
        value={selectedDate}
        onChange={(val) => {
          setSelectedDate(val);
          props.handleChange(moment(val).format(props.inputFormat));
        }}
        minDate={props.minDate ? moment(props.minDate) : null}
        maxDate={props.maxDate ? moment(props.maxDate) : null}
        renderInput={(params) => <TextField helperText={props.helperText} fullWidth className="w-100" {...params} error={!!props.error}/>}
      />
    </LocalizationProvider>
  );
}

export default CustomDateInput;
CustomDateInput.defaultProps = {
  inputFormat: "DD/MM/YYYY"
};

CustomDateInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  inputFormat: PropTypes.string
};
