import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

import TopNav from "./TopNav";
// import MedicineBgUI from "./MedicineBgUI";
import Modal from "../elements/modal";
import { showError } from "../redux/apiCallReducer";
import { useDispatch, useSelector } from "react-redux";

const Layout = () => {
  const dispatch = useDispatch();
  const showErrorModal = useSelector((state) => state?.apiCall);
  const profileData = useSelector((state) => state?.user?.profileData);
  console.log("---- hey hey ", showErrorModal, profileData);
  console.log({ inLayout: showErrorModal });
  return (
    <Fragment>
      <TopNav />
      <Outlet />
      {/*<MedicineBgUI />*/}
      <Modal
        useOneButton
        openModal={!!showErrorModal.errorMessage}
        cancelText="Okay"
        cancelEvent={async () => {
          await dispatch(showError({ isError: false, errorMessage: "" }));
        }}
        title={"વીરધામ"}
        modalBodyContent={showErrorModal.errorMessage}
        type="error"
        // className={"gujarati-font-model"}
        className='gujarati-font-model'
      />
    </Fragment>
  );
};

export default Layout;
