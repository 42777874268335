import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";


import IconButton from '@mui/material/IconButton';

import OutlinedInput from '@mui/material/OutlinedInput';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from "@mui/material";

export const TextInput = (props) => {
  const handleOnChange = (e) => {
    console.log("On Change Not Provided To Input");
  };

  return (
    <Box noValidate autoComplete="off" className="simple-input"
         padding={props.margin} paddingX={props.marginX} paddingY={props.marginY}>
      <TextField
        style={props.style}
        fullWidth
        id={props.id || "textInput"}
        name={props.name || "textInput"}
        label={props.label || ""}
        required={props.required}
        rows={props.rows}
        multiline={props.multiline || props.rows > 1}
        type={props.type || "text"}
        variant={props.variant || "outlined"}
        value={props.value || ""}
        disabled={props.disabled}
        onChange={props.onChange || handleOnChange}
        onBlur={props.onBlur}
        error={props.error || false}
        helperText={props.helperText || ""}
        placeholder={props.placeholder || ""}
        endAdornment={props.endAdornment}
      />
    </Box>
  );
};

export const PasswordInput = (props) => {

  const handleOnChange = (e) => {
    console.log("On Change Not Provided To Password Input");
  };

  return (
    <Box noValidate autoComplete="off" className="password-input"
      padding={props.margin} paddingX={props.marginX} paddingY={props.marginY}>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <InputLabel htmlFor={props.id || "passInput"}>Password</InputLabel>
        <OutlinedInput
          id={props.id || "passInput"}
          name={props.name || 'passInputName'}
          style={props.style}
          fullWidth
          label={props.label || ""}
          required={props.required}
          type={props.type || "text"}
          variant={props.variant || "outlined"}
          value={props.value || ""}
          disabled={props.disabled}
          onChange={props.onChange || handleOnChange}
          onBlur={props.onBlur}
          error={props.error || false}
          helperText={props.helperText || ""}
          placeholder={props.placeholder || ""}
          endAdornment={props.endAdornment}
        />
      </FormControl>
    </Box>
  )
}

TextInput.defaultProps = {
  label: "",
  type: "text",
  variant: "outlined",
  helperText: "",
  placeholder: "",
  rows: 1,
  disabled: false,
  required: false,
  endAdornment: {}
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.any,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.object // pass any component which is relavent to MUI
};
