// packages
import * as React from "react";
import { useEffect, useState } from "react";

// redux operations
import { useDispatch, useSelector } from "react-redux";

// material UI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

// assets
import Logo from "../assets/images/logo.png";

// containers
import UserTopNavAvatar from "./UserTopNavAvatar";
import useAPICall from "../hooks/useAPICall";
import { CONSTANTS_API } from "../constants/constants";

export default function TopNav() {
  // if user is logged in then we need to show the menu icon on top-nav
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const openUrl = (href) => {
    // props.toggleDrawer(false);
    window.open(href, "_blank");
  };

  // information icon pop-up related anchor
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openInfoPopUp = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeInfoPopUP = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Box className="topnav" sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="secondary">
          <Toolbar
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: "10px"
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "start",
                display: "flex"
              }}
            >
              <Popover
                id={"helper-popup"}
                open={open}
                anchorEl={anchorEl}
                onClose={closeInfoPopUP}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
              >
                <List>
                  {/*<ListItem disablePadding>*/}
                  {/*  <ListItemButton onClick={() => openUrl("tel:1300003310")}>*/}
                  {/*    <ListItemIcon>*/}
                  {/*      <CallIcon color="primary" />*/}
                  {/*    </ListItemIcon>*/}
                  {/*    <ListItemText primary="1300 003 310" />*/}
                  {/*  </ListItemButton>*/}
                  {/*</ListItem>*/}
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        openUrl("mailto:veerdhamharidwar@gmail.com")
                      }
                    >
                      <ListItemIcon>
                        <EmailIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="veerdhamharidwar@gmail.com" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>

              <img src={Logo} alt="logo" className="topnav-logo" />
            </Box>

            {/* If user is not logged in and using a mobile device then this block will appear */}
            {!isLoggedIn && (
              <ContactSupportIcon
                sx={{ display: { xs: "flex", sm: "none" } }}
                aria-describedby={"helper-popup"}
                variant="contained"
                onClick={openInfoPopUp}
                fontSize="large"
              />
            )}

            {/* This box will not display in mobile or small screens */}
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "end",
                display: { xs: "none", sm: "flex" }
              }}
            >
              <Link
                href="mailto:veerdhamharidwar@gmail.com"
                underline="none"
                sx={{ display: "flex", marginLeft: "20px" }}
              >
                <EmailIcon sx={{ marginRight: "5px" }}></EmailIcon>{" "}
                <Typography color="secondary">
                  veerdhamharidwar@gmail.com
                </Typography>
              </Link>
            </Box>

            {isLoggedIn && <UserTopNavAvatar />}
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
