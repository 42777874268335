import { createSlice } from "@reduxjs/toolkit";

export const userReducers = createSlice({
    name: 'userInfo',
    initialState: {
        isLoggedIn: false,
        profileData: null,
    },
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        }, setProfileData: (state, action) => {
            state.profileData = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setIsLoggedIn, setProfileData } = userReducers.actions;

export default userReducers.reducer;
