import { createSlice } from "@reduxjs/toolkit";

export const apiCallReducers = createSlice({
    name: 'apiCall',
    initialState: {
        isError: false,
        errorMessage: ''
    },
    reducers: {
        showError: (state, action) => {
            console.log("Setting Error: ", action.payload);
            state.isError = action.payload.isError || false;
            state.errorMessage = action.payload.errorMessage || "";
        },
    },
})

// Action creators are generated for each case reducer function
export const { showError } = apiCallReducers.actions;

export default apiCallReducers.reducer;
