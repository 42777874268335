import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { InstaLabel } from "../elements/label";
import { ROOM_STATUS } from "../constants/constants";
import { CustomIconButton } from "../elements/button";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicOffIcon from '@mui/icons-material/MicOff';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { SubmitButton, InstaButton } from "../elements/button";

// audio recorder
let mediaRecorder;
let audioChunks = [];

const RoomBookingForm = (props) => {

    const selectedRoomData = props.selectedRoomData || {};
    const selectedDate = props.selectedDate || null;

    const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
        useReactMediaRecorder({ audio: true });

    const playRecordedAudio = () => {
        const audio = new Audio(mediaBlobUrl);
        audio.play();
        console.log("Recorded Video Played: ", audio);
    }

    const clearData = () => {
        resetVariables();
        props.closeModal()
    }

    const resetVariables = () => {
        clearBlobUrl();
    }

    const confirmRoomBook = async () => {

        const formData = new FormData();
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const audioFile = new File([audioBlob], 'voice.wav', { type: 'audio/wav' });

        formData.append('file', audioFile);  // preparing to send to the server
        formData.append("buildingId", selectedRoomData.buildingId._id);
        formData.append("floorId", selectedRoomData.floorId._id);
        formData.append("roomId", selectedRoomData._id);
        formData.append("status", selectedRoomData.status === ROOM_STATUS.AVAILABLE ? ROOM_STATUS.NOT_AVAILABLE : ROOM_STATUS.AVAILABLE);
        formData.append("bookingDate", selectedDate);

        await props.updateRoomBooking(formData);
        resetVariables();
    }


    return (
        <Box>
            <Grid container>
                <Grid item xs={12} marginBottom={2}>
                    <InstaLabel title={`શું તમે રૂમ ${selectedRoomData ? selectedRoomData.roomName : ''} ${selectedRoomData && selectedRoomData.status === ROOM_STATUS.AVAILABLE ? 'બુક કરવા માંગો છો?' : 'બુકિંગ રદ કરવા માંગો છો?'}`} />
                </Grid>
                <Grid item xs={6}>
                    {
                        status !== 'recording' ? (
                            <CustomIconButton className="record-btn" variant="contained" size="large" icon={<KeyboardVoiceIcon />} onClick={startRecording} />
                        ) : (
                            <CustomIconButton className="pause-record-btn" variant="contained" size="large" icon={<MicOffIcon />} onClick={stopRecording} />
                        )
                    }
                </Grid>
                <Grid item xs={6} marginBottom={3}>
                    {
                        status === 'idle' && (<InstaLabel title="કૃપા કરીને અવાજ રેકોર્ડ કરો" />)
                    }
                    {
                        status === 'recording' && (<InstaLabel title="અવાજ રેકોર્ડ થઇ રહ્યો છે" />)
                    }

                    {
                        status === 'stopped' && (
                            <CustomIconButton className="audio-play-btn" variant="contained" size="large" icon={<PlayCircleFilledWhiteIcon />} onClick={playRecordedAudio} />
                        )
                    }

                </Grid>

                <Grid item xs={12} textAlign="center" marginY="20px" sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <InstaButton label="ના" className="ml-10 font-22" onClick={() => clearData()} variant="outlined" />
                    {
                        status === 'stopped' && (
                            <InstaButton label="હા" className="ml-10 font-22" onClick={() => confirmRoomBook()} />
                        )
                    }
                </Grid>
            </Grid>
        </Box>
    )
};

export default RoomBookingForm;