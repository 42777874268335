import React, { useEffect } from "react";
import axios from "axios";
import { appRouteNames, BASE_URL } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../redux/apiCallReducer";
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn, setProfileData } from "../redux/userReducer";

const useAPICall = (apiObj) => {
  const [data, setData] = React.useState(null);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.user?.profileData);
  const navigate = useNavigate();

  const makeAPICall = (
    reqType,
    reqURL,
    headers,
    dataObj,
    params,
    successCallback,
    failureCallback,
    showErrorMsg,
    timeout,
    accessToken
  ) => {
    let header = {};
    if (headers && Object.keys(headers).length) {
      header = headers;
    } else if (accessToken) {
      header = {
        Authorization: accessToken
      };
    } else {
      header = {
        Authorization: `Bearer ${authData ? authData["accessToken"] : ""}`
      };
    }
    axios({
      method: reqType,
      url: reqURL,
      baseURL: BASE_URL,
      headers: header,
      data: dataObj || {},
      params: params || {},
      timeout: timeout || 60000,
      onUploadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
        // console.log("API call on process");
      },
      onDownloadProgress: function (progressEvent) {
        // Do whatever you want with the native progress event
        // console.log("API call on Download process");
      }
    }).then((res) => {
      setData(res?.data);
      successCallback(res?.data);
    }).catch(async (err) => {
      setData(null);
      console.log("API Error: ", err, err.message);
      let errorMessage = "";
      if (err?.response?.data?.statusCode === 403 && err?.response?.data?.type === "SESSION_EXPIRED") {
        navigate(appRouteNames.loginPage);
        dispatch(showError({ isError: false, errorMessage: "" }));
        setTimeout(() => {
          dispatch(setIsLoggedIn(false));
          dispatch(setProfileData(null));
          dispatch(showError({ isError: true, errorMessage: "Session expired" }));
        }, 300);
      } else if (
        err?.response &&
        err?.response?.data &&
        err?.response?.data?.message
      ) {
        errorMessage = err?.response?.data?.message;
      } else {
        errorMessage = "Network error! Please try again later";
      }
      if (showErrorMsg) {
        await dispatch(showError({ isError: true, errorMessage: errorMessage }));
      }
      failureCallback(err);
    });
  };

  const noUseFunction = (data) => {
    // ? this function can be ignored
  };

  useEffect(() => {
    if (apiObj && apiObj.reqURL) {
      makeAPICall(
        apiObj.reqType,
        apiObj.reqURL,
        apiObj.headers || {},
        apiObj.dataObj || {},
        apiObj.params || {},
        apiObj.successCallback || noUseFunction,
        apiObj.failureCallback || noUseFunction,
        apiObj.showErrorMsg !== false, // show by default
        apiObj.timeout,
        apiObj.accessToken || null
      );
    }
  }, [apiObj]);

  return [data];
};

export default useAPICall;
